/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const dentistHero = {
  title: "Dentist credit card processing POS system & software",
  subtext:
    "Bring order to marketing your dental practice, managing appointments, taking payments, and connecting with patients",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/dentist/demo",
  },
};
export const dentistSubHero = {
  title: "Tech for every aspect of your practice",
  list: [
    "Attract new patients with a strong online presence",
    "Reduce no-shows & cancellations",
    "Boost patient retention to grow revenue",
    "Get funds faster with more ways for patients to pay",
  ],
  subtext:
    "The experience you provide patients is everything—from the moment they find you online until they pay their bill following an appointment. SpotOn can help with a simple yet powerful software platform designed to streamline operations, save you time, and increase revenue.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/dentist/demo",
  },
};

export const dentistWhiteGlove = {
  mainTitle: "Personalized service & support",
  title: "Let someone care for you for a change",
  content:
    "We’re in the business of helping your business succeed and grow. Our team will work with you one-on-one to customize a technology suite that makes life easier for you, your staff, and your patients. And we’ll continue to be there for you with local service and 24/7 technical support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/dentist/demo",
  },
};

export const dentistLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "A single, simplified solution",
      blockSubTitle:
        "Simplify how you run your back office and the patient checkout process with SpotOn. Our cloud-based dental office software is thoughtfully designed to streamline numerous time-consuming tasks, while creating a seamless experience for your staff and patients that will help you save time and increase revenue.",
      blockImg: "dentist-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/dentist/demo",
      },
    },
  ],
};

export const dentistTrusted = [
  {
    title: "30 – 40%",
    subtext: "Increase in sales with online booking",
  },
  {
    title: "40%",
    subtext: "Decrease in no-shows with automated reminders",
  },
  {
    title: "42%",
    subtext: "Of patients prefer to schedule online",
  },
  {
    title: "25 – 95%",
    subtext: "Your return on every dollar spent on email marketing",
  },
];

export const dentistMultiSection = {
  sectionTitle: "Payment and marketing solutions for dentists",
  featureBlocks: [
    {
      blockTitle: "Book appointments online 24/7",
      blockSubTitle:
        "Streamline operations by allowing your patients to book and change appointments online—no apps or logins needed. Plus, reduce no-shows and increase revenue overall, while keeping your patients well-informed.",
      blockList: [
        "Online booking from your website and Facebook page, available 24/7",
        "Interactive staff calendaring",
        "Automated appointment reminders and 2-way patient texting",
        "No-show protection",
        "Mailing list builder",
      ],
      blockImg: "dentist-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/dentist/demo",
      },
    },
    {
      blockTitle: "Make payments & invoicing easy",
      blockSubTitle:
        "Easily take office payments, send invoices, and even set up recurring payment plans—with one integrated system. SpotOn will set you up with a payment solution that’s quick and easy for your staff and patients, and puts more money in your pocket with next-day funding and transparent pricing.",
      blockList: [
        "In-person payments, including Apple Pay and Google Pay",
        "Online payments & deposits",
        "Custom invoices & reminders",
        "Recurring payments",
        "Customer data capture",
      ],
      blockImg: "dentist-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/dentist/demo",
      },
    },
    {
      blockTitle: "Shine with a professional website",
      blockSubTitle:
        "Get a professional new website that’s optimized to help your dental practice appear more prominently on Google searches and drive more business. Our team of experts will build a professional site for you at a do-it-yourself price.",
      blockImg: "dentist-d.png",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom web address",
        "Online appointment booking",
        "Self-service dashboard",
        "Lifetime support",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/dentist/demo",
      },
    },
    {
      blockTitle: "Stay connected with patients",
      blockSubTitle:
        "Stay top-of-mind with your patients. With SpotOn Marketing, you can easily send limited-time offers, promote new services, send regular health tips, and track campaign performance.",
      blockImg: "dentist-e.png",
      blockList: [
        "Save time with automated campaigns",
        "Send time-sensitive offers to prompt new visits",
        "Drive traffic to your business or website",
        "Solidify your digital presence with Facebook, email, and push notifications",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/dentist/demo",
      },
    },
    {
      blockTitle: "Manage your online reputation",
      blockSubTitle:
        "Finding time to manage and respond to reviews can be tricky, and a good reputation is essential for your practice to succeed. SpotOn Review Management allows you to do it on the go—from your computer or smartphone. Respond immediately to every review, and address unfavorable reviews to make things right.",
      blockList: [
        "See all your online reviews in one place",
        "Get notified every time you get a new review",
        "Easily shift to review sites to respond",
        "Monitor your reputation as it improves over time",
      ],
      blockImg: "dentist-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/dentist/demo",
      },
    },
  ],
};

export const dentistCTA = {
  title: "Sign up for your demo.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/dentist/demo",
  },
};

export const dentistTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const dentistArticles = {
  title: "Expert articles & advice on growing your business",
  bgText: "Articles.",
};

export const hairCar = {
  // Uncomment the following line and remove
  // the other title once we add more than one vertical
  // title: 'We know health & beauty',
  title: "We know health & beauty",
  subtext:
    "Our appointment and scheduling software is great for all kinds of salons, spas, and health & beauty providers.",
  slides: [
    {
      bg: "hair-salon.png",
      cardTitle: "Hair salon",
      cardContent: "More appointments, fewer no-shows",
      icon: "hair-salon.png",
      linkTo: "/health-and-beauty/hair-salons",
    },
    {
      bg: "nail-salon.png",
      cardTitle: "Nail salons",
      cardContent: "Increase sales and get paid fast",
      icon: "nail-salon.png",
      linkTo: "/health-and-beauty/nail-salons",
    },
    {
      bg: "tanning.png",
      cardTitle: "Tanning salons",
      cardContent: "Increase sales and get paid fast",
      icon: "tanning.png",
      linkTo: "/health-and-beauty/tanning-salon",
    },
    {
      bg: "barber.png",
      cardTitle: "Barbershop",
      cardContent: "Increase sales and get paid fast",
      icon: "barber.png",
      linkTo: "/health-and-beauty/barber",
    },
  ],
};

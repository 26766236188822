import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  dentistHero,
  dentistSubHero,
  dentistWhiteGlove,
  dentistLargeFeatureA,
  dentistTrusted,
  dentistMultiSection,
  dentistCTA,
  dentistTestimonials,
  dentistArticles,
  // dentistFaqs,
  hairCar,
} from "../../../data/subverticals/health-and-beauty/dentist";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";

import heroBg from "../../../images/hero-images/hair-salon-white-glove.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);

const whiteGloveImport = "dentist-white-glove.png";

const dentists = () => {
  return (
    <Layout>
      <SEO
        title="Dentist Credit Card Processing POS System | SpotOn"
        description="SpotOn's dental office pos software offers payment processing, appointment booking, & marketing solutions to grow your business. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/health-and-beauty/dentist/"
      />
      <BHero sectionData={dentistHero} heroBg="dentist-hero.png" />
      <NoVisualsCustom sectionData={dentistSubHero} complex />
      <WhiteGlove
        sectionData={dentistWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={dentistLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={dentistTrusted} />
      <LargeFeatures
        sectionData={dentistMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={dentistCTA} />
      {/* <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} /> */}
      <TestmonialReviews sectionData={dentistTestimonials} />
      <Articles
        sectionData={dentistArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={hairCar} />
      {/* <Faqs sectionData={dentistFaqs} /> */}
    </Layout>
  );
};

export default dentists;
